import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { ReactElement, ReactNode } from 'react'

export type AbsoluteLayoutProps = {
  children: ReactNode
  sx?: SxProps
}

export function AbsoluteLayout({ children, sx }: AbsoluteLayoutProps): ReactElement {
  return (
    <Box
      sx={{
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        position: 'fixed',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
